import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { window, document } from 'browser-monads';

import styles from './login.module.less';

const { location }: Window = window;
const { referrer }: Document = document;

const Auth = () => {
  const isLogin = () => location.pathname.match(/\/login\/?/);
  const isLogout = () =>
    location.pathname.match(/\/logout\/?/) && !referrer.match(/\/logout\/?/);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (isLogin()) {
      setMessage('Signing in...');
    } else if (isLogout()) {
      setMessage('Signing out...');
      location.assign(referrer);
    }
  }, [isLogin, isLogout, referrer]);

  return (
    <div className={styles.loader}>
      <Spin tip={message} size="large" />
    </div>
  );
};

export default Auth;
