import React from 'react';
import Auth from '@components/auth';
import { Page, Section } from '@layout/page';

const LoginPage = () => {
  return (
    <Page id="logout-page">
      <Section padding={`128px 0 16px 0`}>
        <Auth />
      </Section>
    </Page>
  );
};

export default LoginPage;
